@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";

.App {
  display: flex;
  width: 100%;
  align-items: stretch;
}

body {
  font-family: "Poppins", sans-serif;
  background-image: url("resources/kepek/background/fabric_1.png");
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.styled-header {
  background-image: url("resources/kepek/background/micro_carbon.png");
}

.styled-text {
  color: white;
}

.sidebar {
  min-width: 300px;
  max-width: 300px;
  background-image: url("resources/kepek/background/otis_redding.png");
  color: #fff;
  margin-left: -350px;
  transition: all 0.5s;
  height: 100%;
  position: fixed;
  border-right: solid black;
}

.sidebar.is-open {
  margin-left: 0;
  transition: 0.5s;
  z-index: 100;
}

.sidebar-header {
  background-image: url("resources/kepek/background/micro_carbon.png");
}

.sidebar-header h3 {
  color: white;
  padding: 1em;
}

.sidebar ul h4 {
  color: white;
  padding: 10px;
}

.nav-link {
  color: black;
}

.nav-link:hover {
  color: darkgray;
}

.nav-link:focus {
  color: black;
}

.menu-open {
  background: white;
}

.items-menu {
  color: black;
  background: white;
}

.sidebar-header > span {
  position: relative;
  float: right;
  margin: 0.5em;
  font-size: 2rem;
  cursor: pointer;
  display: none;
}
.side-menu {
  height: calc(100vh - 130px);
  overflow-y: scroll;
}

.side-menu::-webkit-scrollbar {
  width: 10px;
}

.side-menu::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 3px;
}

.side-menu::-webkit-scrollbar-thumb:hover {
  background: black;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
.content {
  padding: 20px;
  margin-left: 0;
  margin-right: 0;
  height: 100vh;
  transition: 0.5s;
}

.content.is-open {
  margin-left: 300px;
  transition: all 0.5s;
}

@media only screen and (max-width: 1800px) {
  .col-style {
    width: 25% !important;
  }
}

@media only screen and (max-width: 1400px) {
  .col-style {
    width: 33.33333% !important;
  }
}

@media only screen and (max-width: 1200px) {
  .col-style {
    width: 50% !important;
  }
}

@media only screen and (max-width: 1000px) {
  .content {
    margin-top: 80px;
  }

  .styled-text {
    max-width: 60%;
  }
}

@media only screen and (max-width: 800px) {
  .col-style {
    width: 100% !important;
  }
}

@media only screen and (max-width: 650px) {

  .topbar {
    margin-left: -20px;
  }

  .sidebar-header {
    background: black;
    visibility: hidden;
  }

  .content.is-open {
    margin-left: 0px;
    margin-top: 370px;
  }

  .sidebar.is-open {
    max-width: 650px;
    width: 100%;
    margin-left: 0;
    transition: all 0.5s, height 0s;
    position: fixed;
  }

  .sidebar {
    max-height: 350px;
    margin-left: -400px;
    margin-top: 120px;
    transition: all 0.5s;
    width: 100%;
    position: absolute;
    overflow-y: scroll;
    border-right: none;
    border-bottom: solid black;
  }

  .image-detailed {
    max-width: 350px !important;
    max-height: 500px;
    display: block;
  }

  .image-container {
    display: inline !important;
  }

  .modal-image {
    max-width: 400px;
  }

  .col-style {
    width: 100% !important;
  }

  .styled-text {
    max-width: 100%;
  }

}

.btn-custom {
  color: black;
  background: silver;
}

.btn-custom:hover  {
  background: #fff;
  color: silver;
}

.btn-custom:active:focus {
  color: white;
  background: darkgray;
}

.card-fixed-size {
  height: calc(100% - 20px);
  box-sizing: border-box;
}

.image-container {
  display: flex;
  align-items: center;
}

.simple-image {
  max-width: 300px;
  max-height: 300px;
}

.image-detailed {
  max-width: 500px;
  max-height: 400px;
}

.modal-image {
  max-width: 100%;
  min-width: 100%;
  max-height: 100vh;
  display: block;
  margin: 0 auto;
}

.cursor-link {
  cursor: pointer;
}

.padding-right {
  padding-right: 20px;
}

.padding-right-double {
  padding-right: 40px;
}

.padding-bottom {
  padding-bottom: 20px;
}

.modal-content {
  max-width: fit-content;
}

.sorozat-card {
  background-image: url("resources/kepek/background/halftone-yellow.png") !important;
}

.card {
  background-image: url("resources/kepek/background/nasty_fabric.png");
  cursor: pointer;
}

.card:hover {
  -webkit-filter: brightness(80%);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
  transform: scale(1.05);
}

.loading-cursor {
  cursor: wait;
}

table {
  border-collapse: collapse;
  font-family: Tahoma, Geneva, sans-serif;
  margin-bottom: 20px;
}

table td {
  padding: 15px;
}

table th {
  background-color: #54585d;
  color: #ffffff;
  font-weight: bold;
  font-size: 15px;
  border: 1px solid #54585d;
  padding-right: 10px;
  padding-left: 10px;
}
table tbody td {
  color: #636363;
  border: 1px solid #dddfe1;
}
table tbody tr {
  background-color: #f9fafb;
}
table tbody tr:nth-child(odd) {
  background-color: #ffffff;
}

.image-bordered {
  padding: 10px;
  border: 1px black;
  box-shadow: 0 0 0 1px black,
  0 0 0 2px #ddd;
}

.col-style {
  width: 20%;
}

.topbar {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
  transition: all 0.5s;
}

.top-margin {
  padding-top: 100px;
 }

.styled-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  padding: 5px;
  font-size: 15px;
}

.centered {
  text-align: center;
}

.padded-container {
  padding: 20px;
}

.bottom-line {
  border-bottom: solid 1px grey;
}